import { memo } from 'react'
import { svgRuntime } from './SvgProvider'

const containerId = 'svg-sprite'
const baseInnerHtml = `
<defs>
  <linearGradient id="gradient-primary" x1="19.007" y1="1.856" x2="1.896" y2="2.013" gradientUnits="userSpaceOnUse"><stop stop-color="#FCBD89" /><stop offset=".494" stop-color="#B78EFF" /><stop offset="1" stop-color="#82FCF4" /></linearGradient>
</defs>
`

// if SSR is enabled, content should be static and synced on the client hydration
const getClientWithSSRInnerHTML = () =>
  typeof document !== 'undefined'
    ? document.getElementById(containerId)?.innerHTML || baseInnerHtml
    : baseInnerHtml

const SvgSprite = memo<{ withSSR?: boolean }>(({ withSSR = true }) => {
  const isClientWithSSRFlow = withSSR && typeof window !== 'undefined'

  let innerHtml = isClientWithSSRFlow ? getClientWithSSRInnerHTML() : baseInnerHtml

  if (!isClientWithSSRFlow && 'getSymbolsHtml' in svgRuntime) {
    innerHtml += svgRuntime.getSymbolsHtml()
  }

  return (
    <svg
      id={containerId}
      xmlns="http://www.w3.org/2000/svg"
      dangerouslySetInnerHTML={{ __html: innerHtml }}
    />
  )
})

SvgSprite.displayName = 'SvgSprite'

export default SvgSprite
